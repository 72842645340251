<template>
  <p class="mt-4 mb-8 no-data" v-if="!list.length">Geen ritten gevonden</p>
  <div v-else class="w-full overflow-x-scroll">
    <table class="w-full">
      <tbody class="whitespace-nowrap">
        <tr
          v-for="(record, recordIndex) in list"
          :key="`${record.uuid}${record.rit?.id}${recordIndex}`"
          class="w-full grid lg:table-row align-top mb-8 card"
          :class="rowClass(record)"
        >
          <td class="order-5 lg:order-1 lg:w-28" v-if="!hideBevestig && !hideInfo && !hideBewerken && !hideAnnuleer">
            <div class="flex lg:flex-col gap-2 text-xs">
              <button v-if="!hideBevestig" :disabled="disabled || record.rit.is_bevestigd" class="btn bg-green-500 text-white h-8" @click="handleBevestigd(record.rit)">
                <i class="fas fa-check"></i> Bevestig
              </button>
              <router-link
                v-if="!hideInfo"
                :to="`/ritten/info/${record.rit.id}`"
                class="btn bg-blue-500 text-white h-8"
              >
                <i class="fas fa-info-circle"></i> {{ record.rit.id }}
              </router-link>
              <router-link
                v-if="!hideBewerken"
                :disabled="disabled || (!record.rit.is_bevestigd && record.rit.is_geannuleerd)"
                :to="`/${record.rit.is_offerte && !record.rit.is_offerte_ingevuld ? 'offerte' : 'ritten'}/bewerken/${record.rit.id}`"
                class="btn bg-yellow-400 text-white h-8"
              >
                <i class="fas fa-edit"></i> Bewerk
              </router-link>
              <button
                v-if="!hideAnnuleer"
                :disabled="disabled || record.rit.is_geannuleerd"
                class="btn bg-red-500 text-white h-8"
                @click="handleAnnuleer(record.rit)"
              >
                <i class="fas fa-times"></i> Annuleer
              </button>
            </div>
          </td>
          <td class="card order-2 relative">
            <div>
              <LabelBlacklisted :value="record.blacklisted" />
              <span v-if="record.rit.is_offerte" class="label-base bg-blue-500 text-white">
                {{ record.rit.is_offerte_ingevuld ? 'Van Offerte' : 'Open Offerte' }}
              </span>
              <RitStatus class="mr-1" :rit="record.rit" />
              <PremiumRit v-if="record.rit.kost_luxe_vervoer > 0" />
              <RitBronDomein class="mr-1" :bron="record.rit.bron" :domein="record.rit.domein" :bedrijf_thema="record.rit.bedrijf_thema" />
              <RitPromotie :promotie="record.rit.promotie_code" hide-empty />
            </div>

            <div class="flex flex-col gap-2 my-2">
              <KlantLijn class="card gray" label="Klant" :klant="record.klant || { firstname: '?' }" />
              <KlantLijn v-if="record.reiziger" class="card gray" label="Reiziger" :klant="record.reiziger || { firstname: '?' }" />
              <span v-if="record.rit.is_factuur_nodig && !record.facturatie" class="card rounded">
                <strong>Facturatie:</strong> <span class="text-yellow-500">Factuur nodig, maar geen facturatie gegevens</span>
              </span>
              <FacturatieLijn v-else-if="record.facturatie" class="card gray" label="Facturatie" :facturatie="record.facturatie" />
            </div>
            <div class="flex flex-row items-center justify-start gap-1">
              <div class="text-sm card gray inline-block" v-if="!record.rit.is_offerte || record.rit.is_bevestigd">
                <strong>Prijs: </strong>&euro;&nbsp;{{ formatPrice(record.rit.prijs) }}
              </div>
              <div class="text-sm card gray inline-block" v-if="uit_onbevestigden">
                <strong>Betaalmethode:&nbsp;</strong>{{ useBetaalmethodeVanRit(record.rit) }}
              </div>
              <div class="text-sm card gray inline-block" v-if="!hideBetaald && record.rit.is_bevestigd">
                <strong :class="{
                  'text-green-500': record.rit.bedrag_ontvangen >= record.rit.prijs,
                  'text-orange-500': record.rit.bedrag_ontvangen > 0 && record.rit.bedrag_ontvangen < record.rit.prijs,
                  'text-red-500': record.rit.bedrag_ontvangen <= 0,
                }">Bedrag betaald: </strong>&euro;&nbsp;{{ formatPrice(record.rit.bedrag_ontvangen) }}
              </div>
              <ButtonAlertOpmerking
                :opmerking="record?.rit?.opmerking"
                :disabled="record.rit.is_geannuleerd"
              />
            </div>
            <BedrijfThemaLogo class="mt-1 mr-auto float-left" :bedrijf="record.rit.bedrijf_thema"></BedrijfThemaLogo>
            <div class="text-right pt-2 pb-0 pr-2 text-xs block italic">
              <strong>Aangemaakt op</strong>: {{ dateTimeStringLocale(record.rit.creation_date) }}
            </div>
          </td>
          <td class="card order-3">
            <div class="flex flex-col card rounded gray">
              <div>
                <strong>Heenreis: </strong>
                <router-link class="hover:underline" target="_blank" :to="{ name: 'Kalender', query: { date: dateString(record.rit.vertrek_datetime) } }">{{ dateTimeStringLocale(record.rit.vertrek_datetime) }}</router-link>
              </div>
              <LabelRitOpties suffix="_heen" hide-bus class="flex-nowrap self-start my-1" :rit="record.rit" />
              <GoogleAdressenVisueel class="card gray pr-4 my-1" :adressen="record.adressen_heen" />
              <GoogleFlightStatus :vlucht="record.rit.vertrek_vlucht" :adressen="record.adressen_heen">Vluchtnummer</GoogleFlightStatus>
            </div>
          </td>
          <td class="card order-4" v-if="!record.rit.is_retour">
            <strong>Retourreis:</strong> <span class="text-red-500 font-bold">Enkele rit!</span>
          </td>
          <td class="card order-4" v-else>
            <div class="flex flex-col card rounded gray">
              <div>
                <strong>Retourreis: </strong>
                <router-link class="hover:underline" target="_blank" :to="{ name: 'Kalender', query: { date: dateString(record.rit.retour_datetime) } }">{{ dateTimeStringLocale(record.rit.retour_datetime) }}</router-link>
              </div>
              <LabelRitOpties suffix="_retour" hide-bus class="flex-nowrap self-start my-1" :rit="record.rit" />
              <GoogleAdressenVisueel class="card gray pr-4 my-1" :adressen="record.adressen_retour" />
              <GoogleFlightStatus :vlucht="record.rit.retour_vlucht"  :adressen="record.adressen_retour">Vluchtnummer</GoogleFlightStatus>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup>
import { defineProps, defineAsyncComponent, computed } from 'vue'
import { dateTimeStringLocale, dateString } from '@/functions/formatDate'
import { formatPrice } from '@/functions/formatNumber'
import useModal from '@/hooks/useModal'
import useBetaalmethodeVanRit from '@/hooks/useBetaalmethodeVanRit'

import PremiumRit from '@/components/UI/Labels/PremiumRit.vue'
import RitStatus from '@/components/UI/Labels/RitStatus.vue'
import RitBronDomein from '@/components/UI/Labels/RitBronDomein.vue'
import RitPromotie from '@/components/UI/Labels/RitPromotie.vue'
import BedrijfThemaLogo from '@/components/UI/Labels/BedrijfThemaLogo.vue'

const ButtonAlertOpmerking = defineAsyncComponent(() => import('@/components/UI/Button/AlertOpmerking.vue'))
const KlantLijn = defineAsyncComponent(() => import('@/components/Table/Planning/KlantLijn.vue'))
const FacturatieLijn = defineAsyncComponent(() => import('@/components/Table/Planning/FacturatieLijn.vue'))
const LabelRitOpties = defineAsyncComponent(() => import('@/components/UI/Labels/RitOpties.vue'))
const GoogleFlightStatus = defineAsyncComponent(() => import('@/components/Google/FlightStatus.vue'))
const GoogleAdressenVisueel = defineAsyncComponent(() => import('@/components/Google/AdressenVisueel.vue'))
const LabelBlacklisted = defineAsyncComponent(() => import('@/components/UI/Labels/Blacklisted.vue'))

const props = defineProps({
  disabled: Boolean,
  hideBevestig: Boolean,
  hideInfo: Boolean,
  hideBewerken: Boolean,
  hideAnnuleer: Boolean,
  uit_onbevestigden: Boolean,
  hideBetaald: Boolean,
  list: {
    type: Array,
    default() {
      return []
    },
  },
})

const emit = defineEmits(['refresh'])
const [openFixedModal] = useModal({ fixed: true })
const callback = () => emit('refresh', true)
const handleBevestigd = (rit) => {
  openFixedModal('FORM_RIT_BEVESTIG', { rit, callback })
}
const handleAnnuleer = (rit) => {
  openFixedModal('FORM_RIT_ANNULEER', { rit, uit_onbevestigden: props.uit_onbevestigden, callback })
}

const rowClass = computed(() => (record) => {
  let classes = []
  if (record.blacklisted) {
    classes = ['blacklisted']
  } else if (record.rit?.kost_luxe_vervoer > 0) {
    classes = ['border-l-8 border-yellow-300 bg-yellow-50']
  }

  return classes
})
</script>

<style scoped lang="scss">
.blacklisted {
  outline: 2px solid black;
  border: 2px solid black;
  border-bottom: 0;
}

table {
  font-size: 90%;

  tbody tr td {
    // padding: 1rem 0.75rem !important;
    @apply px-2 py-4;
    @apply border-0 lg:border-b-4 border-gray-200
  }
  tbody tr:last-child td {
    @apply border-b-0
  }
}
</style>
